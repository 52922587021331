import React from 'react'
import {
  KiteCell,
  KiteColumn,
  KiteDataTable,
  KiteProgressIndicator,
  KiteRow,
  KiteTableHeader,
} from '@kite/react-kite'
import { RkTableSortDirectionType } from '@kite/react-kite/dist/data-table/KiteDataTable/KiteDataTable'
import Pagination from './Pagination/Pagination'
import { RkPaginationVariant } from '@kite/react-kite/dist/pagination/KitePagination/KitePagination'

import './TableStyles.scss'

const getSortKey = (sortKey: any) => {
  if (sortKey === 'asc') {
    return 'ascending'
  }
  if (sortKey === 'desc') {
    return 'descending'
  }
  return sortKey
}

interface IColumn {
  label: string
  sortKey?: string
  accessorKey: string
  width?: string
  render?: any
  fixed?: string
  className?: string
  headerClassName?: string
}

export enum Spacing {
  Compact = 'compact',
  Default = 'default',
  Comfortable = 'comfortable',
}

interface ICustomTableProps {
  title?: string
  columns: Array<IColumn>
  currentSortKey?: string
  sortDirection?: RkTableSortDirectionType
  onSort?: any
  data: any
  displayRowDetailFn?: (row: any) => boolean
  rowDetailTemplate?: any
  spacing?: Spacing
  currentPage?: number
  onPageChange?: (page: number) => void
  itemsPerPage?: number
  onItemsPerPageChange?: (page: number) => void
  totalItems?: number
  pageOptions?: number[]
  loading?: boolean
  paginationVariant?: RkPaginationVariant
  hidePagination?: boolean
}

const CustomTable = ({
  title = '',
  columns,
  currentSortKey,
  sortDirection,
  onSort,
  data = [],
  rowDetailTemplate,
  displayRowDetailFn,
  spacing = Spacing.Default,
  currentPage, // eslint-disable-next-line @typescript-eslint/no-empty-function
  onPageChange = () => { },
  itemsPerPage, // eslint-disable-next-line @typescript-eslint/no-empty-function
  onItemsPerPageChange = () => { },
  totalItems,
  pageOptions,
  loading,
  paginationVariant = 'default',
  hidePagination = false,
}: ICustomTableProps) => {
  return (
    <>
      <div className='table-wrapper'>
        {loading && <KiteProgressIndicator useOverlay id='kp1' message='' title='Loading' />}
        <div className='table-container'>
          <KiteDataTable
            title={title}
            borders='cell'
            spacing={spacing}
            currentSortKey={currentSortKey}
            sortDirection={getSortKey(sortDirection)}
            onSort={onSort}
          >
            <KiteTableHeader>
              {columns.map((column) => {
                return (
                  <KiteColumn
                    className={
                      column?.fixed
                        ? `fixed ${column?.fixed} ${column?.headerClassName}`
                        : column?.headerClassName
                    }
                    key={column?.sortKey}
                    sortKey={column?.sortKey}
                  >
                    <div style={{ width: column?.width || '200px' }}>{column?.label}</div>
                  </KiteColumn>
                )
              })}
            </KiteTableHeader>
            <tbody>
              {data.map((row: any, index: number) => (
                <>
                  <KiteRow key={'kite-table-row-' + index}>
                    {columns.map((column) => {
                      return (
                        <KiteCell
                          key={column?.accessorKey}
                          className={
                            column?.fixed
                              ? `fixed ${column?.fixed} ${column?.className}`
                              : column?.className
                          }
                        >
                          {column?.render ? (
                            column.render(row)
                          ) : (
                            <div className='t-cell' style={{ width: column?.width || '200px' }}>
                              {row[column?.accessorKey]}
                            </div>
                          )}
                        </KiteCell>
                      )
                    })}
                  </KiteRow>
                  {displayRowDetailFn && displayRowDetailFn(row) &&
                    <KiteRow key={'kite-table-row-detail' + index} className="kiterowdetail">
                      <KiteCell></KiteCell>
                      <KiteCell colSpan={columns.length - (columns.filter(c => !!c.fixed).length ?? 0)}>
                        {rowDetailTemplate(row)}
                      </KiteCell>
                      <KiteCell></KiteCell>
                    </KiteRow>}
                </>
              ))}
            </tbody>
          </KiteDataTable>
        </div>
        {!hidePagination && (
          <Pagination
            currentPage={Number(currentPage)}
            onPageChange={onPageChange}
            itemsPerPage={Number(itemsPerPage)}
            onItemsPerPageChange={onItemsPerPageChange}
            totalItems={Number(totalItems)}
            pageOptions={pageOptions}
            variant={paginationVariant}
          ></Pagination>
        )}
      </div>
    </>
  )
}
export default CustomTable
