import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ApiService from '../../services/ApiService'
import { appApiEndPoints } from '../../constants/ApiEndPoints'

export interface IBatchDetailData {
    SchedDate: string,
    DataCenter: string,
    TotalDownloadFilesFiltered: number,
    Downloaded: string,
    ExportFolderPath: string
}

export interface IIBatchDetailDataState {
    batchActivityId: string,
    batchDetailData: IBatchDetailData[]
  status: string
  error: any
}

const initialState: IIBatchDetailDataState = {
  batchActivityId: '',
  batchDetailData: [],
  status: 'idle',
  error: null
}

export const fetchDetailData = createAsyncThunk('batchDetail/fetchData', async (params: URLSearchParams) => {
  const response = await ApiService.getData(appApiEndPoints.batchDetailDataURL, params)
  return { batchActivityId: params.get('BatchActivityID'), batchDetailData: response?.data};
})

export const BatchDetailSlice = createSlice({
  name: 'batchDetailData',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action?.payload
    },
    resetDetailData: (state) => {
      state.batchActivityId = '';
      state.status = initialState.status;
      state.batchDetailData = [];
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDetailData.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchDetailData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.batchActivityId = action?.payload?.batchActivityId ?? ''
        state.batchDetailData = action?.payload?.batchDetailData as IBatchDetailData[]
      })
      .addCase(fetchDetailData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action?.error?.message || 'Failed to fetch batch detail data'
      })
  },
})
export const { setError, resetDetailData } = BatchDetailSlice.actions
export default BatchDetailSlice.reducer
