import React, { useEffect, useRef } from 'react'
import {
  KiteAlert,
  KiteIcon,
  KiteTooltip,
} from '@kite/react-kite'
import {
  copyToClipBoard,
  formatDateWithCustomFormat,
  formatDateWithTime,
} from '../../../utils/utils'
import CustomTable, { Spacing } from '../../../components/table/Table'
import type { AppDispatch, RootState } from '../../../redux/Store'
import { useDispatch, useSelector } from 'react-redux'

import './BatchActivityTableStyles.scss'
import { setError } from '../../../redux/BatchDetail/BatchDetailSlice'
import { IBatchActivityData } from '../../../redux/BatchActivity/BatchActivityModels'
import CustomToast from '../../../components/toast/CustomToast'

interface IBatchActivityTableProps {
  row: IBatchActivityData
  spacing: Spacing
}

const BatchActivityDetail = ({ row, spacing = Spacing.Default }: IBatchActivityTableProps) => {
  const dispatch: AppDispatch = useDispatch()
  const {
    batchDetailData = [],
    status,
    error
  } = useSelector((state: RootState) => state.batchDetailData)

  const [toastStatus, setToastStatus] = React.useState(false)
  const [toastMsg, setToastMsg] = React.useState('')

  const handleToastExpire = () => {
    setToastStatus(false)
    setToastMsg('')
  }

  const columns = [
    {
      label: 'Schedule Date',
      accessorKey: 'SchedDate',
      width: '100px',
      render: (row: any) => (
        <span className='date'>
          <KiteIcon className='cell-icon' size='0.9rem' icon='ki-calendar' />
          <span> {formatDateWithCustomFormat(row?.SchedDate, 'MM/dd/yyyy')}</span>
        </span>
      ),
    },
    {
      label: 'Exported Data Center',
      accessorKey: 'DataCenter',
      width: '150px',
    },
    {
      label: 'File Count',
      accessorKey: 'TotalDownloadFilesFiltered',
      width: '100px'
    },
    {
      label: 'Exported On',
      accessorKey: 'Downloaded',
      width: '100px',
      render: (row: any) => (

        <span className='date'>
          <span>{formatDateWithTime(row?.Downloaded)}</span>
        </span>

      ),
    },
    {
      label: 'Export Path',
      accessorKey: 'ExportFolderPath',
      width: '100%',
      render: (row: any) => {
        const handleCopy = () => {
          copyToClipBoard(row?.ExportFolderPath)
          setToastStatus(true)
          setToastMsg('Copied Export Path')
        }
        return (<KiteTooltip
          className='cell-file-path'
          type='truncation'
          id='kt1'
          text='Click to copy'
          direction='bottom'
        >
          <div className='cell-file-path' onClick={handleCopy}>
            <span className='textnobreak' >{row?.ExportFolderPath}</span>
            <span className='cell-icon'>
              <KiteIcon icon='ki-copy' />
            </span>
          </div>
        </KiteTooltip>)
      }
    }
  ]

  const isLoading = status === 'loading'
  const handleClearError = () => {
    dispatch(setError(''))
  }
  return (
    <>
      {error && (
        <div className='alert-element'>
          <KiteAlert
            onClose={handleClearError}
            autoFocus={false}
            level='page'
            type='error'
            description={error}
            dismissible
          />
        </div>
      )}
      <CustomTable
        columns={columns}
        data={batchDetailData || []}
        spacing={spacing}
        loading={isLoading}
        hidePagination={true}
      />
      <CustomToast status={toastStatus} message={toastMsg} onExpire={handleToastExpire} />
    </>
  )
}

export default BatchActivityDetail
