import { configureStore } from '@reduxjs/toolkit'
import FiltersDataReducer from './Filters/FiltersSlice'
import BatchActivityDataReducer from './BatchActivity/BatchActivitySlice'
import BatchDetailDataReducer from './BatchDetail/BatchDetailSlice'
import ConfigurationsDataReducer from './Configurations/ConfigurationsSlice'
import SourcesDataReducer from './Sources/SourcesSlice'
import SourcesDetailsReducer from './Sources/SourceDetailSlice'
import ExportSchedulesDataReducer from './ExportSchedules/ExportSchedulesSlice'
import ImportSchedulesDataReducer from './ImportSchedules/ImportSchedulesSlice'
import DuplicatesDataReducer from './Duplicates/DuplicatesSlice'
import DuplicatesFileSetupDataReducer from './Duplicates/DuplicatesFileSetupSlice'
import BulkConfigDataReducer from './BulkConfigurations/BulkConfigSlice'
import AuditLogDataReducer from './AuditLog/AuditLogSlice'

export const store = configureStore({
  reducer: {
    filtersData: FiltersDataReducer,
    batchActivityData: BatchActivityDataReducer,
    batchDetailData: BatchDetailDataReducer,
    configurationsData: ConfigurationsDataReducer,
    sourcesData: SourcesDataReducer,
    exportSchedulesData: ExportSchedulesDataReducer,
    importSchedulesData: ImportSchedulesDataReducer,
    duplicatesData: DuplicatesDataReducer,
    duplicatesFileSetupData: DuplicatesFileSetupDataReducer,
    sourceDetails: SourcesDetailsReducer,
    bulkConfigData: BulkConfigDataReducer,
    auditLogData: AuditLogDataReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
